import { faPersonHiking } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import styled from 'styled-components';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import SEO from '../components/SEO';
import { Text } from 'legacy-ui';
import Container from '../sections/common/Container';
import Page from '../sections/common/Page';
import { SharedData } from '../types/serverData';
import { useTranslation } from 'next-i18next';
import { loadSharedDataFromCMS } from 'utils/http';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticPropsContext } from 'next';
import { LanguageKey } from 'types/common';
import { FooterCMSResult } from 'types/cms/footerTypes';
import { HeaderCMSResult } from 'types/cms/headerTypes';
import { useRouter } from 'next/router';

const Content = styled.div`
  margin: 128px auto 96px auto;
  text-align: center;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin: 192px auto 144px auto;
  }
  svg {
    height: 96px;
    margin-bottom: 32px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      height: 124px;
    }
  }
  h1 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 48px;
  }
`;

const translations: Record<string, { headerData: HeaderCMSResult; footerData: FooterCMSResult; translations: Record<string, string> }> = {
  'en-DK': {
    headerData: {
      SearchLabel: 'Search properties',
      Menu: {
        id: 3,
        Label: 'Menu',
        Links: [
          {
            id: 119,
            Label: 'Rental housing',
            Link: '/rental-housing',
            External: false,
          },
          { id: 120, Label: 'New build', Link: '/new-build', External: false },
          { id: 24, Label: 'About us', Link: '/about', External: false },
          { id: 25, Label: 'Help', Link: '/help', External: false },
          { id: 122, Label: '-', Link: '#', External: false },
          {
            id: 23,
            Label: 'Propstep for Business',
            Link: 'https://business.propstep.com',
            External: true,
          },
        ],
      },
      Login: {
        LogInHeadline: 'Log in or sign up',
        SignUpHeadline: 'Sign up',
        ConfirmHeadline: 'Confirm email',
        ConfirmDescription: 'We have sent a one-time code to your email. Enter it below to confirm your email and log in.',
        LogInButton: 'Continue',
        SignUpButton: 'Sign up',
        ConfirmButtonPrimary: 'Log in',
        ConfirmButtonSecondary: 'Change email',
        ConfirmNoCodeText: 'Have you not received your code yet?',
        ConfirmResendCodeText: 'Request another code in',
        ConfirmResendCodeLink: 'Request a new code',
        InputEmailLabel: 'Email',
        InputEmailPlaceholder: 'Enter your email',
        InputEmailErrorValid: 'Enter a valid email',
        InputEmailErrorRequired: 'Email is required',
        InputOneTimeCodeLabel: 'One-time code',
        InputOneTimeCodePlaceholder: 'Enter your one-time code',
        InputOneTimeCodeErrorRequired: 'Enter a 6 digit code',
        InputOneTimeCodeErrorInvalid: 'This code is invalid',
        InputFirstNameLabel: 'First name',
        InputFirstNameErrorRequired: 'First name is required',
        InputLastNameLabel: 'Last name',
        InputLastNameErrorRequired: 'Last name is required',
        InputConfirmEmailLabel: 'Confirm email',
        InputConfirmEmailErrorValid: 'Enter a valid email',
        InputConfirmEmailErrorMatch: 'The email does not match',
        CheckboxAcceptTermsText: 'I accept the [terms](/terms) and the [privacy policy](/privacy)',
        InputConfirmEmailErrorRequired: 'Please confirm your email',
        CheckoxAcceptTermsErrorRequired: 'You must accept the terms',
        Label: 'Log in',
        InputEmailErrorTaken: 'Email already exists',
        GeneralErrorMessage: 'Something went wrong, please try again later.',
        LogInDescription: 'Propstep is 100% free and gives you a secure, digital rental experience.',
        SignUpDescription: '',
      },
      UserMenu: {
        ApplicationsLabel: 'Applications',
        SavedSearchesLabel: 'Saved searches',
        AdminLabel: 'Administration',
        AccountLabel: 'Account',
        LogoutLabel: 'Log out',
        InterestListsLabel: 'Interest lists',
      },
      WhiteLabel: { BackToSiteText: 'Back to site' },
    },
    footerData: {
      FacebookLink: 'https://facebook.com/propstep/',
      InstagramLink: 'https://instagram.com/propstep/',
      LinkedinLink: 'https://linkedin.com/company/propstep./',
      YoutubeLink: 'https://www.youtube.com/channel/UCGShdPiB2YjyibulZ57z4ZQ',
      LegalText: '© 2022 Propstep ApS (VAT DK39121166)',
      Links: [
        { id: 113, Label: 'Privacy', Link: '/privacy', External: false },
        { id: 114, Label: 'Terms', Link: '/terms', External: false },
        { id: 115, Label: 'Help', Link: '/help', External: false },
        { id: 127, Label: 'Sitemap', Link: '/sitemap', External: false },
      ],
    },
    translations: {
      PageTitle: 'Page not found',
      PageDescription: 'The page you are looking for couldn’t be found or has been moved.',
      GoToHomepage: 'Go to the homepage',
    },
  },
  'da-DK': {
    headerData: {
      SearchLabel: 'Søg lejebolig',
      Menu: {
        id: 4,
        Label: 'Menu',
        Links: [
          {
            id: 123,
            Label: 'Lejeboliger',
            Link: '/lejebolig',
            External: false,
          },
          { id: 124, Label: 'Nybyggeri', Link: '/nybyggeri', External: false },
          { id: 26, Label: 'Byguides', Link: '/city-guides', External: false },
          { id: 27, Label: 'Artikler', Link: '/articles', External: false },
          { id: 125, Label: 'Ordbog', Link: '/ordbog', External: false },
          { id: 29, Label: 'Om os', Link: '/about', External: false },
          { id: 30, Label: 'Hjælp', Link: '/help', External: false },
          { id: 126, Label: '-', Link: '#', External: false },
          {
            id: 28,
            Label: 'Propstep for Business',
            Link: 'https://business.propstep.com',
            External: true,
          },
        ],
      },
      Login: {
        LogInHeadline: 'Log ind eller opret bruger',
        SignUpHeadline: 'Opret bruger',
        ConfirmHeadline: 'Bekræft email',
        ConfirmDescription: 'Vi har sendt en engangskode til din mail. Udfyld den herunder for at bekræfte din e-mailadresse og fortsætte.',
        LogInButton: 'Fortsæt',
        SignUpButton: 'Opret bruger',
        ConfirmButtonPrimary: 'Log ind',
        ConfirmButtonSecondary: 'Skift email',
        ConfirmNoCodeText: 'Har du endnu ikke modtaget en kode?',
        ConfirmResendCodeText: 'Send en ny kode om',
        ConfirmResendCodeLink: 'Send mig en ny kode',
        InputEmailLabel: 'Email',
        InputEmailPlaceholder: 'Indtast din email',
        InputEmailErrorValid: 'Din email er ugyldig',
        InputEmailErrorRequired: 'Email er påkrævet',
        InputOneTimeCodeLabel: 'Engangskode',
        InputOneTimeCodePlaceholder: 'Indtast din engangskode',
        InputOneTimeCodeErrorRequired: 'Indtast en 6-cifret kode',
        InputOneTimeCodeErrorInvalid: 'Engangskoden er ugyldig',
        InputFirstNameLabel: 'Fornavn',
        InputFirstNameErrorRequired: 'Fornavn er påkrævet',
        InputLastNameLabel: 'Efternavn',
        InputLastNameErrorRequired: 'Efternavn er påkrævet',
        InputConfirmEmailLabel: 'Gentag email',
        InputConfirmEmailErrorValid: 'Din email er ugyldig',
        InputConfirmEmailErrorMatch: 'Emails matcher ikke',
        CheckboxAcceptTermsText: 'Jeg accepterer [vilkår](/vilkaar) og [privatlivspolitik](/privatliv)',
        InputConfirmEmailErrorRequired: 'Email er påkrævet',
        CheckoxAcceptTermsErrorRequired: 'Du skal accepterer vilkårene',
        Label: 'Log ind',
        InputEmailErrorTaken: 'Der findes allerede en bruger med denne email',
        GeneralErrorMessage: 'Noget gik galt. Venligst prøv igen senere.',
        LogInDescription: 'Propstep er 100% gratis og giver dig en sikker, digital lejeoplevelse.',
        SignUpDescription: '',
      },
      UserMenu: {
        ApplicationsLabel: 'Anmodninger',
        SavedSearchesLabel: 'Gemte søgninger',
        AdminLabel: 'Administration',
        AccountLabel: 'Konto',
        LogoutLabel: 'Log ud',
        InterestListsLabel: 'Interesselister',
      },
      WhiteLabel: { BackToSiteText: 'Tilbage til siden' },
    },
    footerData: {
      FacebookLink: 'https://facebook.com/propstep/',
      InstagramLink: 'https://instagram.com/propstep/',
      LinkedinLink: 'https://linkedin.com/company/propstep./',
      YoutubeLink: 'https://www.youtube.com/channel/UCGShdPiB2YjyibulZ57z4ZQ',
      LegalText: '© 2022 Propstep ApS (CVR 39121166)',
      Links: [
        { id: 116, Label: 'Privatliv', Link: '/privatliv', External: false },
        { id: 117, Label: 'Vilkår', Link: '/vilkaar', External: false },
        { id: 118, Label: 'Hjælp', Link: '/hjaelp', External: false },
        { id: 128, Label: 'Sitemap', Link: '/sitemap', External: false },
      ],
    },
    translations: {
      PageTitle: 'Siden findes ikke',
      PageDescription: 'Siden du forsøgte at åbne findes ikke længere eller er flyttet.',
      GoToHomepage: 'Tilbage til forsiden',
    },
  },
};

const Page404 = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const language = (locale as LanguageKey) === 'default' ? 'en-DK' : (locale as LanguageKey);
  return (
    <Page header={{ data: translations[language]['headerData'] }} footer={{ data: translations[language]['footerData'] }}>
      <SEO title={translations[language]['translations'].PageTitle} images={[]} />
      <Container>
        <Content>
          <FontAwesomeIcon icon={faPersonHiking} />
          <Heading size="r" tag="h1">
            {translations[language]['translations'].PageTitle}
          </Heading>
          <Text>{translations[language]['translations'].PageDescription}</Text>
          <Link href="/" passHref>
            <Button>{translations[language]['translations'].GoToHomepage}</Button>
          </Link>
        </Content>
      </Container>
    </Page>
  );
};

export default Page404;
